import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
import { Box } from '@primer/react';
import ComponentLayout from '~/src/layouts/component-layout';
import { AccessibilityLink } from '~/src/components/accessibility-link';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = ComponentLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <h2>{`Overview`}</h2>
    <p>{`The autocomplete component is an enhanced text input that makes it easier to choose one or more values from a long list of options. The list of options is displayed when a user focuses the input.`}</p>
    <h2>{`Anatomy`}</h2>
    <p>{`Autocomplete is made up of a form input field, label, and overlay of menu options. The label is required but may be visually hidden. Optionally, a leading visual and/or clear button may be displayed.`}</p>
    <p><img parentName="p" {...{
        "src": "https://user-images.githubusercontent.com/18661030/170589956-7c0f2498-b55c-4013-8c99-ef5bc1844218.png",
        "alt": "autocomplete component diagram with an input label stating pick a branch, empty focused input field with a search icon and clear button, and an overlay menu listing search options"
      }}></img></p>
    <h2>{`Usage`}</h2>
    <h3>{`Text input`}</h3>
    <p><img parentName="p" {...{
        "src": "https://user-images.githubusercontent.com/18661030/170590638-b7868803-6558-4df9-b462-20d00420b9f5.png",
        "alt": "two side by side autocomplete inputs, one showing a single select filled state, and another showing a multi select filled state with clearable tokens"
      }}></img></p>
    <p>{`The Autocomplete text input has all the same design functionality and properties as a regular text input including size options, width, leading visual, trailing action, and states. When a single item is selected from the menu, it will display in the input as regular text. If multiple items are selected, they will display as clearable tokens within the input.`}</p>
    <h3>{`Menu`}</h3>
    <p><img parentName="p" {...{
        "src": "https://user-images.githubusercontent.com/18661030/170590641-42b27a3a-799a-4c1e-aaef-774990aed345.png",
        "alt": "two side by side autocomplete inputs focused with open menus. One shows a single select menu, the second shows a multi select menu with checkboxes"
      }}></img></p>
    <p>{`The menu is a list of options for the field's value. It appears as a list in a non-modal dialog that the user may select one or more values from. The menu uses Overlay and ActionList designs for size, structure and interaction.`}</p>
    <h4>{`Menu item rendering`}</h4>
    <p>{`By default, menu items are rendered as a single line of text. The list in the menu is rendered using the `}<a parentName="p" {...{
        "href": "/components/action-list"
      }}>{`Action List`}</a>{` component, so menu items can be rendered with all of the same options as Action List items.`}</p>
    <Box mb={3} display="flex" alignItems="flex-start" flexDirection={['column', 'column', 'column', 'column', 'row']} sx={{
      gap: 4
    }} mdxType="Box">
  <img width="456" alt="opened autocomplete menu stating: no selectable options" src="https://user-images.githubusercontent.com/18661030/170601327-97307d21-a33c-4a9f-9e2f-ad9e444a161d.png" />
  <Box as="p" mt="0" mdxType="Box">
    If no options are available based on the search term, the menu will display a message that says "No selectable
    options". A concise, custom message may be used in place of the default.
  </Box>
    </Box>
    <Box mb={3} display="flex" alignItems="flex-start" flexDirection={['column', 'column', 'column', 'column', 'row']} sx={{
      gap: 4
    }} mdxType="Box">
  <img width="456" alt="opened autocomplete menu with an add item action" src="https://user-images.githubusercontent.com/18661030/170589959-73608127-771d-4f7e-a78d-11b31433141d.png" />
  <Box as="p" mt="0" mdxType="Box">
    If a user is not limited to a pre-defined list of options, an additional item can be rendered in the menu to select
    the value that has been typed into the text input.
  </Box>
    </Box>
    <Box mb={3} display="flex" alignItems="flex-start" flexDirection={['column', 'column', 'column', 'column', 'row']} sx={{
      gap: 4
    }} mdxType="Box">
  <img width="456" alt="opened autocomplete menu with a loading spinner" src="https://user-images.githubusercontent.com/18661030/170589963-ab3e211b-1082-4fdb-928e-eddd2b773ba3.png" />
  <Box as="p" mt="0" mdxType="Box">
    A loading indicator should be displayed while the data for the list of options is being populated. The loading
    indicator helps the user understand that the list is not empty, it's just not done loading.
  </Box>
    </Box>
    <h2>{`Sort and filter behavior`}</h2>
    <h3>{`Sorting`}</h3>
    <p>{`The order of the items should be ordered in a way that makes it easy for a user to find a specific value. This could mean items may be ranked by how likely a user is to pick that option (for example, ordering labels by the number of times they've been used in that repository), or it could simply be in alphabetical order.`}</p>
    <p>{`If multiple values can be selected, the default behavior is to move the selected items to the top of the list after the menu is closed. If this sorting logic is not helpful for your use case, you may override this behavior with a more appropriate sorting logic.`}</p>
    <h3>{`Filtering`}</h3>
    <p>{`By default, menu items are filtered based on whether or not they match the value of the text input. The default filter is case-insensitive.`}</p>
    <p>{`Custom filtering logic can be applied if the default filtering behavior does not make sense for your use case. However, it is strongly discouraged to disable filtering entirely.`}</p>
    <h2>{`Accessibility`}</h2>
    <h3>{`Known accessibility issues (GitHub staff only)`}</h3>
    <AccessibilityLink label="AutoComplete" mdxType="AccessibilityLink" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      